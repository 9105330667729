<script setup lang="ts">
import type { NuxtError } from '#app'

import { ROUTE_NAV } from '@/shared/config'
import IconAveolaLogo from '@/shared/public/IconAveolaLogo.svg'

defineProps({
  error: {
    type: Object as () => NuxtError,
    default() {
      return { statusCode: 200 }
    },
  },
})

const localePath = useLocalePath()

function handleError() {
  clearError({ redirect: localePath(ROUTE_NAV.cabVideoChat) })
}
</script>

<template>
  <div class="bg-gray-200 h-svh w-full">
    <div class="max-w-640 mx-auto px-20 pt-80">
      <div class="flex justify-center shrink-0 grow-0 mb-80">
        <IconAveolaLogo class="my-24" />
      </div>
      <h1 class="text-black mb-32 !text-[120px] text-h1-bold leading-[120px] text-center">
        {{ error.statusCode }}
      </h1>
      <p class="text-h3-semibold text-center mb-8">{{ $t('error.title') }}</p>
      <p class="text-h5-medium text-center text-gray-800 mb-32">
        {{ $t('error.subtitle') }}
      </p>
      <button
        class="py-20 bg-red w-full rounded-12 text-button-semibold text-white"
        @click="handleError"
      >
        {{ $t('error.button') }}
      </button>
    </div>
  </div>
</template>
