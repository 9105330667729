const AUTH_TOKEN_CACHE = 'acessTokenWebview'

export function webviewAccessTokenGet(): string {
  if (process.server) return ''
  const authToken = sessionStorage.getItem(AUTH_TOKEN_CACHE)
  if (!authToken) return ''
  return JSON.parse(authToken)
}

export function webviewAccessTokenSet(authToken: string) {
  if (process.server) return
  sessionStorage.setItem(AUTH_TOKEN_CACHE, JSON.stringify(authToken))
}

export function webviewAccessTokenReset() {
  if (process.server) return
  sessionStorage.removeItem(AUTH_TOKEN_CACHE)
}
