<script setup>
useHead({
  htmlAttrs: {
    translate: 'no',
  },
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
