export const ROUTE_NAV = Object.freeze({
  cabVideoChat: '/cab/video-chat/',
  cabChat: '/cab/chat/',
  cabTopProfiles: '/cab/top-profiles/',
  cabRewards: '/cab/rewards/',
  cabStories: '/cab/stories/',
  cabProfile: '/cab/profile/',
  cabProfileSettings: '/cab/profile-settings/',
  cabCallHistory: '/cab/call-history/',
  cabInvoiceHistory: '/cab/invoice-history/',
  cabBlocklist: '/cab/blocklist/',
  cabPrivateCall: '/cab/private-call/',

  doNotSellMyPersonalInfo: '/do-not-sell-my-personal-info/',
  doubleConnection: '/double-connection/',
  terms: '/terms-and-conditions/',
  policy: '/privacy-policy/',
  cookiePolicy: '/cookie-policy/',
  purchaseTerms: '/purchase-terms/',
  subscriptionTerms: '/subscription-terms/',

  authWelcome: '/auth/welcome/',
  authLoginEmail: '/auth/login-email/',
  authForgotPassword: '/auth/forgot-password/',
  authRegistrationEmail: '/auth/registration-email/',
  authRegistrationName: '/auth/registration-name/',
  authRegistrationGender: '/auth/registration-gender/',
  authCreateNewPassword: '/auth/create-new-password/',
  authEmailConfirmed: '/auth/email-confirmed/',
})

export const ROUTE_NAV_WEBVIEW = Object.freeze({
  index: '/webview/',
  error: '/webview-error/',
  cabBundles: '/webview/bundles/',
  cabHistory: '/webview/history/',
  cabRefund: '/webview/refund/',
  cabReport: '/webview/report/',
  cabPayment: '/webview/payment/',
  cabPayment3ds: '/webview/payment-3ds/',
  cabPaymentError: '/webview/payment-error/',
  cabPaymentSuccess: '/webview/payment-success/',
})
