import { env } from '@/shared/config'
import { tokenGet, webviewAccessTokenGet } from '@/shared/lib/accessToken'
import { deviceIdGet } from '@/shared/lib/deviceId'
import { eventBus } from '@/shared/lib/eventBus'
import { languageBrowser } from '@/shared/lib/languageBrowser'

import type {
  ApiRequestMethod,
  ApiResponseState,
  ApiResponseBase,
  ApiRequestOptions,
  ApiEventBusErrorCallbackPayload,
} from './api.types'

const EVENT_BUS_CONTEXT = 'api'
const EVENT_BUS_TARGET = 'error'
const HTTP_STATUS_CODE_UNAUTHORIZED = 401
const HTTP_STATUS_CODE_BANNED = 1011
const HTTP_STATUS_CODE_USER_REMOVED = 1012

function headersBase(options?: ApiRequestOptions) {
  const headers = {
    'X-Application': env.APPLICATION,
    'X-Version-Backend': env.VERSION_BACKEND,
    'X-Device-Id': deviceIdGet(),
    Locale: languageBrowser(),
    Authorization: '',
  }
  if (options?.webview) {
    const accessToken = webviewAccessTokenGet()
    if (accessToken) headers.Authorization = `Bearer ${accessToken}`
  } else {
    const authToken = tokenGet()
    if (authToken?.accessToken) {
      headers.Authorization = `Bearer ${authToken.accessToken}`
    }
  }

  return headers
}

function optionsBase() {
  return {
    timeout: 10000,
  }
}

async function requestApi<R>(
  baseURL: string,
  method: ApiRequestMethod,
  path: string,
  body?: any,
  options?: ApiRequestOptions,
) {
  const state: ApiResponseState<R> = {
    isOk: false,
    isError: false,
    isAuthError: false,
    isInvalid: false,
    data: undefined,
    error: undefined,
  }

  try {
    const response = await $fetch<R & ApiResponseBase>(path, {
      baseURL,
      method,
      body,
      headers: headersBase(options),
      ...optionsBase(),
      onResponseError({ response }) {
        const eventBusName = eventBus.nameGenerate(
          EVENT_BUS_CONTEXT,
          EVENT_BUS_TARGET,
          String(response.status),
        )
        eventBus.emit(eventBusName, {
          path,
        })
        switch (response.status) {
          case HTTP_STATUS_CODE_UNAUTHORIZED:
            state.isAuthError = true
            break
          default:
            state.isError = true
        }

        throw response._data.error
      },
    })
    if (response.error) throw response.error
    state.isOk = true
    state.data = response as (R & ApiResponseBase) | undefined

    /*
      The catch should have any type because
      TS doesn't let you use types other than "any" or "unknown" within a catch clause
    */
  } catch (error: any) {
    state.isError = true
    const errorDetails = error?.errorsDetails || error?.errors_details
    if (error) error.errorsDetails = errorDetails
    if (error?.errors_details) delete error.errors_details
    state.error = error
    if (
      state.error?.code === HTTP_STATUS_CODE_BANNED ||
      state.error?.code === HTTP_STATUS_CODE_USER_REMOVED
    ) {
      const eventBusName = eventBus.nameGenerate(
        EVENT_BUS_CONTEXT,
        EVENT_BUS_TARGET,
        String(state.error.code),
      )
      eventBus.emit(eventBusName, state.error.message)
    }
    state.isInvalid = true
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return Object.freeze(state)
  }
}

export function useApiErrorEventBus(statusCode: 401 | 500 | 1011 | 1012) {
  const eventBusName = eventBus.nameGenerate(
    EVENT_BUS_CONTEXT,
    EVENT_BUS_TARGET,
    String(statusCode),
  )
  return {
    on(callback: (data: ApiEventBusErrorCallbackPayload) => void) {
      eventBus.on(eventBusName, callback)
    },
    off(callback: (data: ApiEventBusErrorCallbackPayload) => void) {
      eventBus.off(eventBusName, callback)
    },
  }
}

export async function useApiAuth<R>(
  path: string,
  method: ApiRequestMethod = 'GET',
  body?: any,
  options?: ApiRequestOptions,
) {
  return await requestApi<R>(env.AUTH_URL, method, path, body, options)
}

export async function useApiApp<R>(
  path: string,
  method: ApiRequestMethod = 'GET',
  body?: any,
  options?: ApiRequestOptions,
) {
  return await requestApi<R>(env.APP_URL, method, path, body, options)
}

export async function useApiTrack<R>(
  path: string,
  method: ApiRequestMethod = 'GET',
  body?: any,
  options?: ApiRequestOptions,
) {
  return await requestApi<R>(env.TRACK_URL, method, path, body, options)
}
